<template>
  <div>
    <div
      v-for="(orderCheckpoint, index) in order.order_checkpoints"
      :key="index"
    >
      <div
        v-if="index < 3"
        class="last-status-card d-flex justify-content-between"
        :class="index < 200 ? 'dashed-bottom' : ''"
      >
        <div class="aside-status-date d-flex flex-column">
          <div class="text-16 mb-2">
            <b>{{
              $helpers.formatShortDate(orderCheckpoint.date_iso, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}</b>
          </div>
          <div class="text-12">
            {{
              $helpers.formatShortTime(orderCheckpoint.date_iso, {
                hour: "numeric",
                minute: "numeric",
              })
            }}
          </div>
        </div>
        <div class="aside-status-detail d-flex flex-column">
          <div class="text-16 mb-2">
            <b>{{
              language.includes("en")
                ? orderCheckpoint.macro_status.description
                : orderCheckpoint.macro_status.description_pt
            }}</b>
          </div>
          <div
            class="text-16 mb-2"
            v-if="order.contract && order.contract.name"
          >
            {{ $t("trackMyOrderViewPage.deliveredBby") }}
            {{ order.contract.name }}
          </div>
          <!-- <div class="text-12 pb-4">Miami, Florida, United States****</div> -->
        </div>
      </div>
    </div>
    <div v-if="order.order_checkpoints && order.order_checkpoints.length > 3">
      <b-collapse id="collapse-status">
        <div
          v-for="(orderCheckpoint, index) in order.order_checkpoints"
          :key="index"
        >
          <div
            v-if="index > 2"
            class="last-status-card d-flex justify-content-between"
            :class="index < 200 ? 'dashed-bottom' : ''"
          >
            <div class="aside-status-date d-flex flex-column">
              <div class="text-16 mb-2">
                <b>{{
                  $helpers.formatShortDate(orderCheckpoint.date_iso, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}</b>
              </div>
              <div class="text-12">
                {{
                  $helpers.formatShortTime(orderCheckpoint.date_iso, {
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}
              </div>
            </div>
            <div class="aside-status-detail d-flex flex-column">
              <div class="text-16 mb-2" v-if="orderCheckpoint.macro_status">
                <b>{{ orderCheckpoint.macro_status.description }}</b>
              </div>
              <div
                class="text-16 mb-2"
                v-if="order.contract && order.contract.name"
              >
                {{ $t("trackMyOrderViewPage.deliveredBby") }}
                {{ order.contract.name }}
              </div>
              <!-- <div class="text-12 pb-4">Miami, Florida, United States****</div> -->
            </div>
          </div>
        </div>
      </b-collapse>
      <div
        v-b-toggle="'collapse-status'"
        class="collapse-button"
        @click="fixBgHeight"
      >
        <i class="fas fa-caret-right mr-3"></i
        >{{ $t("trackMyOrderViewPage.expandOrderHistory") }}
        <i class="fas fa-caret-right ml-3"></i>
      </div>
    </div>
    <div v-else class="collapse-button"></div>
  </div>
</template>

<script>
import i18n from "../../../src/i18n.js";

export default {
  props: {
    order: {
      type: Object,
      require: true,
    },
  },
  methods: {
    fixBgHeight() {
      let times = 1;
      const loadBar = setInterval(() => {
        if (times < 200) {
          times++;
          let grayBg = document.getElementById("gray-background");
          if (grayBg) {
            grayBg.style.height = `100%`;
            let bgHeight = grayBg.offsetHeight;
            bgHeight -= 264; //compensar -> .body-wrapper { top: -16.5rem; }
            grayBg.style.height = `${bgHeight}px`;
          }
        } else {
          clearInterval(loadBar);
        }
      }, 1);
    },
  },
  computed: {
    language() {
      const currentLanguage = i18n.locale;
      return currentLanguage;
    },
  },
};
</script>

<style lang="scss" scoped>
.last-status-card {
  margin-top: 1.6rem;
}
.dashed-bottom {
  border-bottom: 1px dashed #a0a0a0;
}
.last-status-card .aside-status-date {
  width: 130px;
  margin-right: 18px;
}
.last-status-card .aside-status-detail {
  width: 100%;
  min-height: 92px;
}
.collapse-button {
  width: 100%;
  height: 16px;
  text-align: center;
  font: normal normal bold 16px/16px var(--font-family-base);
  color: var(--gray-60);
  text-decoration: none;
  cursor: pointer;
  background-color: var(--white);
  margin-top: -5px;
}
.collapse-button.not-collapsed > i {
  transition: all 0.7s ease;
  transform: rotate(-90deg);
}
.collapse-button.collapsed > i {
  transform: rotate(90deg);
  transition: all 0.7s ease;
}
.collapse-button:hover {
  color: var(--black);
  text-decoration: underline;
}
</style>
