<template>
  <div>
    <div v-if="desktopOnly" class="d-flex justify-content-between">
      <div class="progress-text" :class="statusStep == 0 ? 'active' : ''">
        {{ $t("trackMyOrderViewPage.statusBar.created") }}
      </div>
      <div class="progress-text" :class="statusStep == 1 ? 'active' : ''">
        {{ $t("trackMyOrderViewPage.statusBar.shiped") }}
      </div>
      <div class="progress-text" :class="statusStep == 2 ? 'active' : ''">
        {{ $t("trackMyOrderViewPage.statusBar.inTransit") }}
      </div>
      <div class="progress-text" :class="statusStep == 3 ? 'active' : ''">
        {{ $t("trackMyOrderViewPage.statusBar.outOfDelivery") }}
      </div>
      <div class="progress-text" :class="statusStep == 4 ? 'active' : ''">
        {{ $t("trackMyOrderViewPage.statusBar.delivery") }}
      </div>
    </div>
    <div v-if="desktopOnly" class="d-flex justify-content-between mt-2">
      <div class="mx-4 progress-ball">
        <div
          class="ball"
          :class="
            statusStep > -1 ? `active step-${statusStep} ${addInfoColor}` : ''
          "
        ></div>
      </div>
      <div class="mx-4 progress-ball">
        <div
          class="ball"
          :class="
            statusStep > 0 ? `active step-${statusStep} ${addInfoColor}` : ''
          "
        ></div>
      </div>
      <div class="mx-4 progress-ball">
        <div
          class="ball"
          :class="
            statusStep > 1 ? `active step-${statusStep} ${addInfoColor}` : ''
          "
        ></div>
      </div>
      <div class="mx-4 progress-ball">
        <div
          class="ball"
          :class="
            statusStep > 2 ? `active step-${statusStep} ${addInfoColor}` : ''
          "
        ></div>
      </div>
      <div class="mx-4 progress-ball">
        <div
          class="ball"
          :class="
            statusStep > 3 ? `active step-${statusStep} ${addInfoColor}` : ''
          "
        ></div>
      </div>
    </div>
    <div
      class="tracking-progressbar"
      :class="`step-${statusStep} ${addInfoColor}`"
    >
      <b-progress
        :height="`${desktopOnly ? '2' : '16'}px`"
        :value="statusStep * 25"
      ></b-progress>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusStep: {
      type: Number,
      require: true,
    },
    addInfoColor: {
      type: String,
      default: "",
    },
    windowWidth: {
      type: Number,
      require: true,
    },
  },
  computed: {
    mobileOnly() {
      if (this.windowWidth < 855) {
        return true;
      }
      return false;
    },
    desktopOnly() {
      if (this.windowWidth > 855) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.first-card .progress-text {
  width: 100%;
  text-align: center;
  font: normal normal medium 14px/24px var(--font-family-base);
  letter-spacing: 0.22px;
  color: var(--gray-30);
  transition: all 0.5s ease;
}
.first-card .progress-text.active {
  font: normal normal bold 14px/24px var(--font-family-base);
  color: #000000;
  transition: all 0.5s ease;
}
.first-card .progress-ball {
  height: 1rem;
  border-radius: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.first-card .progress-ball.active {
  font: normal normal bold 14px/24px var(--font-family-base);
  transition: all 0.5s ease;
  border-radius: 50%;
}

.first-card .progress-ball > .ball {
  width: 8px;
  height: 8px;
  background: #cdcdcd;
  border-radius: 50%;
  transition: all 0.5s ease;
  z-index: 1;
}
.first-card .progress-ball > .ball.active {
  width: 1rem;
  height: 1rem;
  //background: red;
}
.first-card .tracking-progressbar {
  position: relative;
  top: -0.56rem; // metade da altura das bolas
  width: 80%;
  margin: auto;
  z-index: 0;
}
</style>
