<template>
  <div>
    <LangButton v-if="lang" class="lang-button-topright" />
    <!-- LangButton.doc usar variavel para construir esse componente por ultimo -->
    <div
      class="tracking-header-background"
      :style="`background: ${primaryColor};`"
    ></div>
    <div
      class="tracking-body-background"
      :style="`background: ${secondaryColor};`"
      id="gray-background"
    >
      <div class="body-wrapper">
        <section
          class="d-flex justify-content-between tracking-header-menu align-items-center"
        >
          <a
            :href="logoLink"
            :target="logoTarget"
            :style="{ color: textColor }"
            class="logo d-flex align-items-center m-0 p-0 text-decoration-none"
            :class="logoTarget ? '' : 'cursor-auto'"
          >
            <template v-if="order.seller.tracking_setting.show_logo">
              <img
                v-show="order.seller.logo && order.seller?.site"
                :src="order.seller.logo"
                alt="logo"
                class="logo-header-menu"
                onerror="this.style.display='none'"
              />
              <h3 class="ml-3">{{ logoFallbackText }}</h3>
            </template>
          </a>
          <div
            v-if="desktopOnly && trackingSettings?.show_contact_info"
            class="d-flex"
            :style="`color: ${textColor} !important;`"
          >
            <div
              class="header-menu-right mx-3"
              v-if="trackingSettings?.contact_us_action_subject"
            >
              <a :href="contactUsAction" :style="{ color: textColor }">
                {{ $t("trackMyOrderViewPage.contactUs") }}
              </a>
            </div>
            <a
              v-show="order.seller.tracking_setting.facebook"
              role="button"
              :href="getSocialLink('facebook')"
              ><FacebookIcon
                class="mx-3"
                :style="`fill: ${textColor} !important;`"
            /></a>
            <a
              v-show="order.seller.tracking_setting.twitter"
              role="button"
              :href="getSocialLink('twitter')"
              ><TwitterIcon
                class="mx-3"
                :style="`fill: ${textColor} !important;`"
            /></a>
            <a
              v-show="order.seller.tracking_setting.instagram"
              role="button"
              :href="getSocialLink('instagram')"
              ><InstagramIcon
                class="mx-3"
                :style="`fill: ${textColor} !important;`"
            /></a>
            <a
              v-show="order.seller.tracking_setting.linkedin"
              role="button"
              :href="getSocialLink('linkedin')"
              ><LinkedinIcon
                class="mx-3"
                :style="`fill: ${textColor} !important;`"
            /></a>
          </div>
        </section>

        <div
          v-if="order.order_number && mobileOnly"
          class="title-order mb-2"
          :style="`color: ${textColor} !important;`"
        >
          <b>{{ $t("order") }}:</b> {{ order.order_number }}
        </div>

        <section
          class="first-card"
          :style="`border-bottom: 3px solid ${
            stepColors[`${addInfoColor ? addInfoColor : statusStep}`]
          };`"
        >
          <div v-if="mobileOnly" class="text-center">
            <div class="text-12 mb-1">
              {{ $t("trackMyOrderViewPage.estimatedDelivery") }}
            </div>
            <div v-if="order.estimated_delivery_date" class="text-18-bold mb-2">
              <b>{{
                $helpers.formatShortDate(order.estimated_delivery_date, {
                  weekday: "short",
                  year: "2-digit",
                  month: "short",
                  day: "2-digit",
                })
              }}</b>
            </div>
            <div
              v-if="order.estimated_delivery_date"
              class="text-12"
              style="margin-bottom: 2.25rem"
              v-html="daysLeft(order.estimated_delivery_date)"
            ></div>
          </div>

          <div class="progress-track">
            <ProgressBar
              :statusStep="statusStep"
              :windowWidth="windowWidth"
              :addInfoColor="addInfoColor"
            />
          </div>

          <div class="progress-details">
            <ProgressBarDetails
              :statusStep="statusStep"
              :addInfoColor="addInfoColor"
              :windowWidth="windowWidth"
              :order="order"
            />
          </div>
        </section>
        <section class="d-flex justify-content-between second-third-card">
          <div v-if="desktopOnly" class="d-flex flex-column last-status-cards">
            <div class="title-card">
              {{ $t("trackMyOrderViewPage.latestStatus") }}
            </div>
            <LatestStatusList :order="order" />
          </div>
          <div
            class="d-flex flex-column aside-cards"
            :class="desktopOnly ? '' : 'w-100'"
          >
            <!-- <div class="mini-card first">
              <div class="title-card mb-3">{{ $t('trackMyOrderViewPage.updateByEmail') }}</div>
              <EmailSideCard />
            </div> -->
            <div class="mini-card second">
              <ShippingSideCard :windowWidth="windowWidth" :order="order" />
            </div>
          </div>
        </section>

        <section
          class="section-banner"
          v-if="trackingSettings?.ad_img && trackingSettings?.show_ad"
        >
          <a
            :href="
              trackingSettings?.ad_url ? addHTTPS(trackingSettings.ad_url) : '#'
            "
            target="_blank"
          >
            <div
              v-if="
                trackingSettings?.ad_main_text ||
                trackingSettings?.ad_link_text_action
              "
              class="banner-info"
              :style="[{ 'background-color': addOpacity(primaryColor) }]"
            >
              <h1 class="banner-title text-white">
                {{ trackingSettings?.ad_main_text }}
              </h1>
              <a
                :href="
                  trackingSettings?.ad_url
                    ? addHTTPS(trackingSettings.ad_url)
                    : '#'
                "
                target="_blank"
                class="link-1 text-white"
                >{{ trackingSettings?.ad_link_text_action }}</a
              >
            </div>
            <img
              class="client-banner"
              :src="trackingSettings?.ad_img"
              width="100%"
            />
          </a>
        </section>
      </div>
    </div>
    <footer
      class="tracking-footer-background text-16-24"
      :style="`background: ${primaryColor}; color: ${textColor} !important;`"
    >
      <template v-if="trackingSettings?.show_footnote">
        <a
          v-show="trackingSettings?.redirect_to_website_when_click_footnote"
          :href="addHTTPS(siteUrl)"
          target="_blank"
          :style="{ color: textColor }"
        >
          {{ trackingSettings?.footnote }}
        </a>
        <span
          v-show="!trackingSettings?.redirect_to_website_when_click_footnote"
          >{{ trackingSettings?.footnote }}</span
        >
      </template>

      <div v-if="mobileOnly">
        <hr class="my-4" :style="`background: ${textColor};`" />
        <section class="d-flex justify-content-between">
          <div>
            <div class="header-menu-right">
              {{ $t("trackMyOrderViewPage.contactUs") }}
            </div>
          </div>
          <div class="d-flex" :style="`color: ${textColor} !important;`">
            <a
              v-show="order.seller.tracking_setting.facebook"
              role="button"
              :href="getSocialLink('facebook')"
              ><FacebookIcon
                class="mx-2"
                :style="`fill: ${textColor} !important;`"
            /></a>
            <a
              v-show="order.seller.tracking_setting.twitter"
              role="button"
              :href="getSocialLink('twitter')"
              ><TwitterIcon
                class="mx-2"
                :style="`fill: ${textColor} !important;`"
            /></a>
            <a
              v-show="order.seller.tracking_setting.instagram"
              role="button"
              :href="getSocialLink('instagram')"
              ><InstagramIcon
                class="mx-2"
                :style="`fill: ${textColor} !important;`"
            /></a>
            <a
              v-show="order.seller.tracking_setting.linkedin"
              role="button"
              :href="getSocialLink('linkedin')"
              ><LinkedinIcon
                class="mx-2"
                :style="`fill: ${textColor} !important;`"
            /></a>
          </div>
        </section>
      </div>
    </footer>
  </div>
</template>

<script>
//page components
import LangButton from "../../components/buttons/LangButton.vue";
import ProgressBar from "../../components/trackmyorder/ProgressBar.vue";
import ProgressBarDetails from "../../components/trackmyorder/ProgressBarDetails.vue";
import LatestStatusList from "../../components/trackmyorder/LatestStatusList.vue";
/* import EmailSideCard from '../../components/trackmyorder/EmailSideCard.vue' */
import ShippingSideCard from "../../components/trackmyorder/ShippingSideCard.vue";

//icons
import FacebookIcon from "@/components/icons/socialicons/FacebookIcon.vue";
import TwitterIcon from "@/components/icons/socialicons/TwitterIcon.vue";
import InstagramIcon from "@/components/icons/socialicons/InstagramIcon.vue";
import LinkedinIcon from "@/components/icons/socialicons/LinkedinIcon.vue";

export default {
  name: "Template",
  props: {
    order: {
      type: Object,
      required: true,
    },
    statusStep: {
      type: Number,
      default: 0,
    },
    addInfoColor: {
      type: String,
      default: "",
    },
    lang: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    LangButton,
    ProgressBar,
    ProgressBarDetails,
    LatestStatusList,
    /*  EmailSideCard, */ ShippingSideCard,
  },
  directives: {
    ad: {
      inserted: function (el) {
        el.addEventListener("error", function () {
          el.style.display = "none";
        });

        el.addEventListener("load", function () {
          el.style.display = "initial";
        });
      },
    },
  },
  data: () => {
    return {
      loaded: false,
      prevRoute: null,
      imageZero: [100, 200, 300, 401, 402, 450, 451, 452, 453, 454],
      imageOne: [400, 403, 500, 617, 618],
      imageTwo: [
        600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611, 612, 613,
        614, 615, 616, 800, 803, 804, 806, 807, 810, 801, 802, 805, 808, 809,
        1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1100, 1101, 1200,
      ],
      imageThree: [700, 701, 702, 703],
      imageFour: [900, 901, 902, 903],
      imageError: [
        801, 802, 805, 808, 809, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007,
        1008, 1100, 1101, 1200, 1303, 1350, 1351, 1352, 1400, 1450, 1451,
      ],
      imageSix: [1300, 1301, 1302, 1304, 1306],
      //imageSixError: [1303, 1350, 1351, 1352, 1400, 1450, 1451],
      imageSeven: [1305, 1400],
      /* primaryColor: "#006464",
        secondaryColor: '#F2F2F2', */
      stepColors: {
        0: "#000F5A",
        1: "#003c50",
        2: "#007850",
        3: "#00aa50",
        4: "#00dc50",
        danger: "#DC0000",
        warning: "#c8c800",
      },
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    logoLink() {
      if (this.trackingSettings?.redirect_to_website_when_click_logo) {
        return this.addHTTPS(this.siteUrl);
      }
      return "#";
    },
    logoTarget() {
      if (this.trackingSettings?.redirect_to_website_when_click_logo) {
        return "_blank";
      }
      return "";
    },
    primaryColor() {
      if (
        this.order.seller &&
        this.order.seller.tracking_setting &&
        this.order.seller.tracking_setting.primary_color
      ) {
        return this.order.seller.tracking_setting.primary_color;
      }
      return "#006464";
    },
    secondaryColor() {
      if (
        this.order.seller &&
        this.order.seller.tracking_setting &&
        this.order.seller.tracking_setting.secondary_color
      ) {
        return this.order.seller.tracking_setting.secondary_color;
      }
      return "#F2F2F2";
    },
    textColor() {
      if (
        this.order.seller &&
        this.order.seller.tracking_setting &&
        this.order.seller.tracking_setting.text_color
      ) {
        return this.order.seller.tracking_setting.text_color;
      }
      return "#FFFFFF";
    },
    mobileOnly() {
      if (this.windowWidth < 855) {
        return true;
      }
      return false;
    },
    desktopOnly() {
      if (this.windowWidth > 855) {
        return true;
      }
      return false;
    },
    logoFallbackText() {
      if (this.trackingSettings?.logo_fallback_type === "company_name") {
        return this.order.seller?.name;
      }

      return this.trackingSettings?.logo_fallback_text;
    },
    contactUsAction() {
      const action = this.trackingSettings?.contact_us_action_click;
      const subject = this.trackingSettings?.contact_us_action_subject;

      const actions = {
        send_mail: `mailto:${subject}`,
        redirect: this.addHTTPS(subject),
      };

      return actions[action] || "";
    },
    trackingSettings() {
      return this.order.seller.tracking_setting;
    },
    siteUrl() {
      const url = this.order.seller?.site;
      if (!url?.startsWith("http")) {
        return `https://${url}`;
      }

      return url;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    setTimeout(() => {
      this.fixBgHeight();
    }, 500);
  },
  methods: {
    addHTTPS(link) {
      if (link.includes("http")) {
        return link;
      }
      return "https://" + link;
    },
    addOpacity(hexCor, opacity = 0.95) {
      hexCor = hexCor.replace(/^#/, "");
      const red = parseInt(hexCor.substring(0, 2), 16);
      const green = parseInt(hexCor.substring(2, 4), 16);
      const blue = parseInt(hexCor.substring(4, 6), 16);

      opacity = parseFloat(opacity);

      const rgbaCor = `rgba(${red},${green},${blue},${opacity})`;
      return rgbaCor;
    },
    hasHistory() {
      return window.history.length > 2;
    },
    getTopValue(className) {
      let returnVal = 0;
      const bodyWrapper = document.getElementsByClassName(className)[0];
      if (bodyWrapper) {
        const computedStyle = getComputedStyle(bodyWrapper);
        returnVal = computedStyle.top;
        returnVal = returnVal.replace("-", "");
        returnVal = returnVal.replace("px", "");
        returnVal = parseFloat(returnVal);
      }
      return returnVal;
    },
    fixBgHeight() {
      let times = 1;
      const topValue = this.getTopValue("body-wrapper");
      const loadBar = setInterval(() => {
        if (times < 200) {
          times++;
          let grayBg = document.getElementById("gray-background");
          if (grayBg) {
            grayBg.style.height = `100%`;
            let bgHeight = grayBg.offsetHeight;
            bgHeight -= topValue; //bgHeight -= 264; compensar -> .body-wrapper { top: -16.5rem; }
            grayBg.style.height = `${bgHeight}px`;
          }
        } else {
          clearInterval(loadBar);
        }
      }, 1);
    },
    getSocialLink(socialNetwork) {
      if (
        socialNetwork &&
        this.order.seller &&
        this.order.seller.tracking_setting &&
        this.order.seller.tracking_setting[socialNetwork]
      ) {
        if (
          !this.order.seller.tracking_setting[socialNetwork].includes(
            "https://"
          ) &&
          !this.order.seller.tracking_setting[socialNetwork].includes("http://")
        ) {
          this.order.seller.tracking_setting[socialNetwork] =
            "https://" + this.order.seller.tracking_setting[socialNetwork];
        }
        return this.order.seller.tracking_setting[socialNetwork];
      }
      return "#";
    },
    daysLeft(date) {
      const remainingDays = (date1, date2) =>
        Math.ceil(Math.abs(date1.getTime() - date2.getTime()) / 86400000);
      const day = new Date(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (
        day.getFullYear() === today.getFullYear() &&
        day.getMonth() === today.getMonth() &&
        day.getDate() === today.getDate()
      ) {
        return `<b>Today.</b>`;
      }
      if (day > today) {
        return `<b>${remainingDays(day, today)} ${this.$t(
          "trackMyOrderViewPage.days"
        )}</b> ${this.$t("trackMyOrderViewPage.fromNow")}.`;
      }
      return `<b>${this.$t("trackMyOrderViewPage.late")} ${remainingDays(
        day,
        today
      )} ${this.$t("trackMyOrderViewPage.days")}</b>`;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style lang="scss" scoped>
//new
.lang-button-topright {
  position: absolute;
  top: 2rem;
  right: 1.5rem;
}
.tracking-header-background {
  height: 19rem;
}
.tracking-body-background {
  padding-bottom: 1rem;
}
.body-wrapper {
  position: relative;
  top: -16.5rem;
  left: 50%;
  transform: translate(-50%);
  //padding-top: 2.5rem; //removido
  width: 75%;
  max-width: 1050px;
}

.tracking-header-menu {
  text-align: right;
  font: normal normal bold 16px/16px var(--font-family-base);
  color: var(--white);
  padding-bottom: 40px;
}
.logo-header-menu {
  max-height: 94px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 0px;
}
.header-menu-right {
  text-align: right;
  text-decoration: underline;
  font: normal normal bold 16px/16px var(--font-family-base);
}
.first-card {
  padding: 2.5rem 5rem;
  margin-bottom: 1rem;
  background: var(--white);
  border-radius: 4px;
}

.second-third-card {
  margin-bottom: 1rem;
}

.progress-track {
  margin-bottom: 40px;
  width: 116%;
  left: -9%;
  position: relative;
}

.flex-column.last-status-cards {
  width: 66.1%; //obrigatorio
  //min-height: 442px; //não precisa?
  background: var(--white);
  border-radius: 4px;
  padding: 24px 24px 20px 24px;
}
.aside-cards {
  width: 32.2%;
  border-radius: 4px;
}
.aside-cards > .mini-card {
  width: 100%;
  background: var(--white);
  border-radius: 4px;
  padding: 24px;
}
.aside-cards > .mini-card.first {
  min-height: 230px;
  margin-bottom: 14px;
}
.aside-cards > .mini-card.second {
  //talvez remover max-height e height se adicionar o mini-card first  (card de email)
  min-height: 12.25rem;
  max-height: 26.88rem;
  height: 100%;
}
.title-card {
  font: normal normal bold 12px/20px var(--font-family-base);
  letter-spacing: 0.48px;
  color: var(--gray-60);
  text-transform: uppercase;
}

//end component last status
.client-banner {
  /*
    width: 100%;
    background: #006464;
    mix-blend-mode: multiply;
    opacity: 0.2;
    */
  height: 240px;
  border-radius: 4px;
  object-fit: cover; //muda o tamanho da imagem...? sim/nao
}

.tracking-footer-background {
  min-height: 64px;
  padding: 1.3rem;
  text-align: center;
  letter-spacing: 0.26px;
  margin: auto;
}

@media only screen and (max-width: 855px) {
  .lang-button-topright {
    position: absolute;
    top: 1.5rem;
    right: 3.1rem;
  }
  .tracking-header-background {
    height: 25rem;
  }
  .tracking-body-background {
    padding-bottom: 2.5rem;
  }
  .body-wrapper {
    top: -20.5rem;
  }
  .tracking-header-menu {
    padding-bottom: 0px;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin-bottom: 2.5rem;
  }
  .title-order {
    text-align: center;
    font: normal normal 600 12px/20px Nunito Sans;
    letter-spacing: 0.19px;
  }
  .header-menu-right {
    font: normal normal 600 12px/20px Nunito Sans;
  }
  .first-card {
    padding: 1.5rem 1.5rem /* 5rem ??? */;
    margin-bottom: 0.5rem;
  }
  .second-third-card {
    margin-bottom: 0.5rem;
  }
  .progress-track {
    margin-bottom: 0.65rem;
  }
  .tracking-footer-background {
    padding: 1.5rem 2.5rem 1.4rem 2.5rem;
    letter-spacing: 0px;
  }
}
@media only screen and (max-width: 480px) {
}

.section-banner a {
  display: block;
  position: relative;
}

.banner {
  &-info {
    width: 338px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem;
  }
  &-title {
    font-size: 2rem;
  }
}
</style>
