<template>
  <div class="shipping-information-card">
    <div class="title-card">
      {{ $t("trackMyOrderViewPage.shippingInformation") }}
    </div>
    <div class="sub-title" :class="desktopOnly ? '' : 'font-weight-bold'">
      {{ $t("trackMyOrderViewPage.lastMilePartner") }}
    </div>
    <div class="higlight-text" v-if="order.contract">
      {{ order.contract.name }}
    </div>
    <div class="sub-title">
      {{ $t("trackMyOrderViewPage.lastMileTrackingNumber") }}
    </div>
    <div
      class="higlight-text"
      :class="desktopOnly ? 'mb-3' : ''"
      v-if="order.volumes"
    >
      {{ order.volumes[0].last_mile_tracking_number }}
    </div>

    <div v-if="mobileOnly">
      <div class="sub-title">{{ $t("trackMyOrderViewPage.destination") }}:</div>
      <div class="higlight-text mb-0">
        <b v-if="order.customer_city"
          >{{ order.customer_city }}, {{ order.customer_state }},
          {{ order.customer_country
          }}<!-- São Paulo, SP, Brazil**** --></b
        >
        <b v-else>Not Found</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      require: true,
    },
    windowWidth: {
      type: Number,
      require: true,
    },
  },
  computed: {
    mobileOnly() {
      if (this.windowWidth < 855) {
        return true;
      }
      return false;
    },
    desktopOnly() {
      if (this.windowWidth > 855) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-information-card {
  .title-card {
    margin-bottom: 0.5rem;
  }
  .sub-title {
    margin-bottom: 0.5rem;
    font: normal normal normal 12px/16px var(--font-family-base);
    letter-spacing: 0.19px;
    color: var(--gray-60);
  }
  .higlight-text {
    margin-bottom: 0.5rem;
    font: normal normal bold 24px/30px var(--font-family-base);
    letter-spacing: 0.02px;
  }
}

@media only screen and (max-width: 855px) {
  .shipping-information-card {
    .title-card {
      margin-bottom: 1.5rem;
      text-align: left;
      font: normal normal bold 12px/20px Nunito Sans;
      letter-spacing: 0.77px;
    }
    .sub-title {
      font-weight: 700;
      margin-bottom: 0.25rem;
      font: normal normal normal 12px/16px var(--font-family-base);
      letter-spacing: 0.19px;
    }
    .higlight-text {
      margin-bottom: 0.75rem;
      font: normal normal bold 20px/28px Nunito Sans;
      letter-spacing: 0px;
    }
  }
}
@media only screen and (max-width: 480px) {
}
</style>
