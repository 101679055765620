<template>
  <div class="d-flex justify-content-between">
    <div v-if="desktopOnly" class="progress-description-icon">
      <DeliveryError v-if="addInfoColor === 'danger'" />
      <DeliveryWarning v-else-if="addInfoColor === 'warning'" />
      <DeliveryZero v-else-if="statusStep === 0" />
      <DeliveryOne v-else-if="statusStep === 1" />
      <DeliveryTwo v-else-if="statusStep === 2" />
      <DeliveryThree v-else-if="statusStep === 3" />
      <DeliveryFour v-else-if="statusStep === 4" />
    </div>
    <div class="progress-description-text">
      <div
        v-if="order.order_checkpoints"
        class="text-12"
        :class="desktopOnly ? 'mb-4' : 'mb-2'"
      >
        <b>
          {{
            $helpers.formatShortDate(order.order_checkpoints[0].date_iso, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }}
        </b>
      </div>
      <div
        v-if="order.order_checkpoints"
        class="mb-2"
        :class="desktopOnly ? 'text-24' : 'text-20-bold'"
      >
        {{
          language.includes("en")
            ? order.order_checkpoints[0].macro_status.description
            : order.order_checkpoints[0].macro_status.description_pt
        }}
      </div>
      <div
        v-if="order.contract && order.contract.name && statusStep != 4"
        class="text-16 mb-3"
      >
        {{ $t("trackMyOrderViewPage.deliveredBby") }} {{ order.contract.name }}
      </div>
      <div v-if="mobileOnly">
        <div
          v-b-toggle="'collapse-status'"
          class="collapse-button"
          @click="fixBgHeight"
        >
          <i class="fas fa-caret-right mr-3"></i
          >{{ $t("trackMyOrderViewPage.expandOrderHistory") }}
          <i class="fas fa-caret-right ml-3"></i>
        </div>
        <b-collapse id="collapse-status">
          <div class="title-card">
            {{ $t("trackMyOrderViewPage.latestStatus") }}
          </div>
          <div
            v-for="(orderCheckpoint, index) in order.order_checkpoints"
            :key="index"
            style="padding-left: 3px"
          >
            <div class="last-status-card d-flex justify-content-between">
              <div
                :class="`aside-status-date d-flex flex-column 
                ${
                  index === 0
                    ? 'first-ball'
                    : index === order.order_checkpoints.length - 1
                    ? 'last-card'
                    : ''
                }`"
              >
                <div
                  :class="`progress-ball ${index === 0 ? 'first-ball' : ''}`"
                >
                  <div class="ball"></div>
                </div>
              </div>
              <div
                v-if="desktopOnly"
                class="aside-status-detail d-flex flex-column"
              >
                <div class="text-16 mb-2">
                  <b>{{
                    $helpers.formatShortDate(orderCheckpoint.date_iso, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  }}</b>
                </div>
                <div class="text-12">
                  {{
                    $helpers.formatShortTime(orderCheckpoint.date_iso, {
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                </div>
                <div class="text-16 mb-2" v-if="orderCheckpoint.macro_status">
                  <b>{{ orderCheckpoint.macro_status.description }}</b>
                </div>
                <div
                  class="text-16 mb-2"
                  v-if="order.contract && order.contract.name"
                >
                  {{ $t("trackMyOrderViewPage.deliveredBby") }}
                  {{ order.contract.name }}
                </div>
                <!-- <div class="text-12 pb-4">Miami, Florida, United States****</div> -->
              </div>
              <div v-else class="aside-status-detail d-flex flex-column">
                <div class="text-16 mb-1" v-if="orderCheckpoint.macro_status">
                  <b>{{ orderCheckpoint.macro_status.description }}</b>
                </div>
                <div
                  :class="`text-12 ${
                    index === order.order_checkpoints.length - 1
                      ? 'mb-0'
                      : 'mb-4'
                  }`"
                >
                  <b>
                    {{
                      $helpers.formatShortDate(orderCheckpoint.date_iso, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}
                    •
                    {{
                      $helpers.formatShortTime(orderCheckpoint.date_iso, {
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <!-- <div v-else class="d-flex flex-column" >
        <div class="text-16 mb-1" >Please, rate your delivery:</div>
        <RatingStars :stars="5" />
        <--**** @changeRated="saveRated($event)"
      </div> -->
      <!-- <div v-if="statusStep != 4" class="text-12">Miami, Florida, United States****</div> -->
      <div
        v-if="statusStep === 4"
        class="text-12"
        :class="mobileOnly ? 'mt-3' : ''"
      >
        <a href="https://nobordistinc.freshdesk.com/support/home">
          If you didn't receive your order, please <u>contact us</u>.
        </a>
      </div>
      <!-- add link de suport here **** -->
    </div>
    <div v-if="desktopOnly" class="progress-description-card">
      <div v-if="order.order_number" class="text-12 mb-4">
        <b>{{ $t("order") }}:</b> {{ order.order_number }}
      </div>
      <div class="text-12 mb-2">
        {{ $t("trackMyOrderViewPage.estimatedDelivery") }}
      </div>
      <div v-if="order.estimated_delivery_date" class="text-16 mb-1">
        <b>{{
          $helpers.formatShortDate(order.estimated_delivery_date, {
            weekday: "short",
            year: "2-digit",
            month: "short",
            day: "2-digit",
          })
        }}</b>
      </div>
      <div
        v-if="order.estimated_delivery_date"
        class="text-12 mb-4"
        v-html="daysLeft(order.estimated_delivery_date)"
      ></div>
      <div class="text-12 mb-1">
        {{ $t("trackMyOrderViewPage.destination") }}
      </div>
      <div class="text-16">
        <b v-if="order.customer_city"
          >{{ order.customer_city }}, {{ order.customer_state }},
          {{ order.customer_country
          }}<!-- São Paulo, SP, Brazil**** --></b
        >
        <b v-else>Not Found</b>
      </div>
    </div>
  </div>
</template>

<script>
/* import RatingStars from '../../components/ratingstars/RatingStars.vue' */

import DeliveryZero from "@/components/icons/tracking/IllustrationDelivery00.vue";
import DeliveryOne from "@/components/icons/tracking/IllustrationDelivery01.vue";
import DeliveryTwo from "@/components/icons/tracking/IllustrationDelivery02.vue";
import DeliveryThree from "@/components/icons/tracking/IllustrationDelivery03.vue";
import DeliveryFour from "@/components/icons/tracking/IllustrationDelivery04.vue";
import DeliveryError from "@/components/icons/tracking/IllustrationError.vue";
import DeliveryWarning from "@/components/icons/tracking/IllustrationWarning.vue";
import i18n from "../../../src/i18n.js";

export default {
  components: {
    DeliveryFour,
    DeliveryThree,
    DeliveryTwo,
    DeliveryOne,
    DeliveryZero,
    DeliveryError,
    DeliveryWarning /* RatingStars */,
  },
  props: {
    statusStep: {
      type: Number,
      require: true,
    },
    addInfoColor: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      require: true,
    },
    windowWidth: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      possibleLangs: {
        en: "en-US",
        zh_CN: "zh-Hans-CN",
        pt_BR: "pt-br",
      },
      rate: 0,
      rated: 0,
    };
  },
  methods: {
    daysLeft(date) {
      const remainingDays = (date1, date2) =>
        Math.ceil(Math.abs(date1.getTime() - date2.getTime()) / 86400000);
      const day = new Date(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (
        day.getFullYear() === today.getFullYear() &&
        day.getMonth() === today.getMonth() &&
        day.getDate() === today.getDate()
      ) {
        return `<b>Today.</b>`;
      }
      if (day > today) {
        return `<b>${remainingDays(day, today)} ${this.$t(
          "trackMyOrderViewPage.days"
        )}</b> ${this.$t("trackMyOrderViewPage.fromNow")}.`;
      }
      return `<b>${this.$t("trackMyOrderViewPage.late")} ${remainingDays(
        day,
        today
      )} ${this.$t("trackMyOrderViewPage.days")}</b>`;
    },
    fixBgHeight() {
      let times = 1;
      const loadBar = setInterval(() => {
        if (times < 200) {
          times++;
          let grayBg = document.getElementById("gray-background");
          if (grayBg) {
            grayBg.style.height = `100%`;
            let bgHeight = grayBg.offsetHeight;
            bgHeight -= 264; //compensar -> .body-wrapper { top: -16.5rem; }
            grayBg.style.height = `${bgHeight}px`;
          }
        } else {
          clearInterval(loadBar);
        }
      }, 1);
    },
  },
  computed: {
    language() {
      const currentLanguage = i18n.locale;
      return currentLanguage;
    },
    mobileOnly() {
      if (this.windowWidth < 855) {
        return true;
      }
      return false;
    },
    desktopOnly() {
      if (this.windowWidth > 855) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-description-icon {
  width: 22.27%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.7s ease;
}
.progress-description-text {
  width: 49.11%;
  padding: 24px 40px;
}
.progress-description-card {
  width: 28.62%;
  padding: 24px;

  //max-height: 206px;
  /* UI Properties */
  background: #f2f2f2;
}

@media only screen and (max-width: 855px) {
  .progress-description-text {
    text-align: center;
    width: 100%;
    padding: 0;
  }

  .title-card {
    font: normal normal bold 12px/20px Nunito Sans;
    letter-spacing: 0.77px;
    text-align: left;
    color: var(--gray-60);
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .progress-ball {
    left: -0.33rem;
    position: relative;
    height: 0.5rem;
    border-radius: 50%;
    width: 100%;
  }
  .progress-ball:not(.first-ball) {
    margin-top: 0.19rem;
  }

  .progress-ball > .ball {
    width: 0.6rem;
    height: 0.6rem;
    background: #000;
    border-radius: 50%;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .progress-ball > .ball.active {
    width: 1rem;
    height: 1rem;
    //background: red;
  }

  //ANALIZAR
  .last-status-card {
    margin-top: 0rem;
  }
  .dashed-bottom {
    border-bottom: 1px dashed #a0a0a0;
  }
  .last-status-card .aside-status-date {
    width: 0.5rem;
    margin-right: 0.87rem;
    border-left: 1px solid #000000;
  }
  .last-status-card .aside-status-date.last-card {
    border-left: 1px dashed black;
  }

  .aside-status-date.first-ball {
    margin-top: 0.35rem;
  }
  .last-status-card .aside-status-detail {
    width: 100%;
    min-height: 3.62rem;
    text-align: left;
  }
  .collapse-button {
    width: 100%;
    height: 16px;
    text-align: center;
    font: normal normal 600 12px/20px Nunito Sans;
    letter-spacing: 0.2px;
    color: var(--gray-60);
    text-decoration: underline;
    cursor: pointer;
    background-color: var(--white);
    margin-top: -5px;
  }
  .collapse-button.not-collapsed > i {
    transition: all 0.7s ease;
    transform: rotate(-90deg);
  }
  .collapse-button.collapsed > i {
    transform: rotate(90deg);
    transition: all 0.7s ease;
  }
  .collapse-button:hover {
    color: var(--black);
    text-decoration: underline;
  }
  //end ANALIZAR
}
@media only screen and (max-width: 480px) {
}
</style>
